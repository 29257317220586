
/** career-details **/

.career-details{
  position: relative;
}

.career-details-content{
  position: relative;
  display: block;
}

.career-details-content h2{
  font-size: 40px;
  line-height: 52px;
  font-weight: 800;
  margin-bottom: 6px;
}

.career-details-content .content-one span{
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: var(--theme-color);
  margin-bottom: 20px;
}

.career-details-content p{
  margin-bottom: 30px;
}

.career-details-content p:last-child{
  margin-bottom: 0px;
}

.career-details-content h3{
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
}

.career-details-content .content-three .list-style-one li:before{
  display: none;
}

.career-details-content .content-three .list-style-one li:after{
  position: absolute;
  content: '';
  background: rgba(34, 34, 34, 1);
  width: 6px;
  height: 6px;
  left: 0px;
  top: 12px;
  border-radius: 50%;
}

.career-details-content .content-four{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.career-details-content .content-four .social-links{
  position: relative;
  display: flex;
  align-items: center;
}

.career-details-content .content-four .social-links li{
  margin-right: 10px;
}

.career-details-content .content-four .social-links li:last-child{
  margin: 0px !important;
}

.career-details-content .content-four .social-links li a{
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  background: rgba(59, 89, 152, 1);
}

.career-details-content .content-four .social-links li:nth-child(2) a{
  background: rgba(85, 172, 238, 1);
}

.career-details-content .content-four .social-links li:nth-child(3) a{
  background: rgba(220, 78, 65, 1);
}

.career-details-content .content-four .social-links li:last-child a{
  background: rgba(63, 114, 155, 1);
}

.career-sidebar{
  position: relative;
  display: block;
  background: #fff;
  box-shadow: 0px 20px 60px 10px rgba(0, 0, 0, 0.07);
}

.career-sidebar h4{
  position: relative;
  display: block;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  background: var(--theme-color);
  padding: 15px 15px;
}

.career-sidebar .info-list{
  position: relative;
  padding: 0px 30px 30px 30px;
}

.career-sidebar .info-list li{
  position: relative;
  display: block;
  padding: 15px 0px 15px 43px;
  border-bottom: 1px solid rgba(222, 222, 222, 1);
}

.career-sidebar .info-list li:last-child{
  border-bottom: none;
}

.career-sidebar .info-list li i{
  position: absolute;
  left: 0px;
  top: 20px;
  font-size: 30px;
  color: var(--theme-color);
}

.career-sidebar .info-list li h6{
  display: block;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  margin-bottom: 5px;
}



/** RTL **/


.rtl .career-sidebar{
  margin-left: 0px;
  margin-right: 40px;
}

.rtl .career-sidebar .info-list li{
  padding-left: 0px;
  padding-right: 43px;
}

.rtl .career-sidebar .info-list li i{
  left: inherit;
  right: 0px;
}

.rtl .career-details-content .content-three .list-style-one li:after{
  left: inherit;
  right: 0px;
}

.rtl .career-details-content .content-four .social-links li{
  margin-right: 0px;
  margin-left: 10px;
}


/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){
  .career-sidebar{
    margin: 30px 0px 0px 0px !important;
  }
}

@media only screen and (max-width: 767px){
  .career-details{
    padding: 70px 0px;
  }
}

@media only screen and (max-width: 599px){

}

@media only screen and (max-width: 499px){
  .career-details-content .content-four{
    display: block;
  }
  
  .career-details-content .content-four .btn-box{
    margin-bottom: 20px;
  }
}










































