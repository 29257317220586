
/** news-section **/

.news-section{
  position: relative;
  overflow: hidden;
}

.news-block-one .inner-box{
  position: relative;
  display: block;
  padding: 238px 30px 30px 30px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 30px;
}

.news-block-one .inner-box:after{
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 0%;
  left: 0px;
  bottom: 0px;
  opacity: 0.8;
  transition: all 500ms ease;
}

.news-block-one .inner-box:hover:after{
  height: 100%;
}

.news-block-one .inner-box:before{
  position: absolute;
  content: '';
  background: -webkit-linear-gradient(-90deg, rgba(33, 34, 38, 0), rgba(33, 34, 38, 0.85));
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

.news-block-one .inner-box .content-box{
  position: relative;
  z-index: 1;
}

.news-block-one .inner-box .post-date{
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 20px;
  color: #222222;
  font-weight: 500;
  background: #fff;
  padding: 10px 15px 10px 40px;
  border-radius: 30px;
  margin-bottom: 25px;
}

.news-block-one .inner-box .post-date i{
  position: absolute;
  left: 15px;
  top: 12px;
  font-size: 16px;
}

.news-block-one .inner-box h3{
  display: block;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 10px;
}

.news-block-one .inner-box h3 a{
  display: inline-block;
  color: #fff;
}

.news-block-one .inner-box h3 a:hover{
  color: var(--theme-color);
}

.news-block-one .inner-box .post-info{
  position: relative;
  display: flex;
  align-items: center;
}

.news-block-one .inner-box .post-info li{
  position: relative;
  font-size: 16px;
  color: #fff;
  padding-left: 25px;
  margin-right: 20px;
}

.news-block-one .inner-box .post-info li:last-child{
  margin: 0px !important;
}

.news-block-one .inner-box .post-info li i{
  position: absolute;
  left: 0px;
  top: 5px;
  font-size: 16px;
  color: var(--theme-color);
}

.news-block-one .inner-box .post-info li a{
  display: inline-block;
  color: #fff;
}

.news-block-one .inner-box .post-info li a:hover{

}

.news-block-one .inner-box .theme-btn{
  padding: 13px 40px;
}

.news-section .pattern-layer .pattern-1{
  position: absolute;
  top: -134px;
  right: 0px;
  width: 237px;
  height: 433px;
  background-repeat: no-repeat;
}

.news-section .pattern-layer .pattern-2{
  position: absolute;
  left: 0px;
  bottom: 275px;
  width: 183px;
  height: 237px;
  background-repeat: no-repeat;
}

.news-block-one .inner-box .theme-btn:before,
.news-block-one .inner-box .theme-btn:after{
  background: var(--theme-color);
}

.news-block-one .inner-box .theme-btn:hover{
  color: #fff !important;
}


/** news-style-two **/

.news-style-two{
  position: relative;
}

.news-style-two .pattern-layer .pattern-1{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 237px;
  height: 433px;
  background-repeat: no-repeat;
}

.news-style-two .pattern-layer .pattern-2{
  position: absolute;
  left: 0px;
  bottom: 275px;
  width: 183px;
  height: 237px;
  background-repeat: no-repeat;
}

.news-block-two .inner-box{
  position: relative;
  display: block;
  padding: 0px 30px 0px 30px;
  margin-bottom: 30px;
}

.news-block-two .inner-box:before{
  position: absolute;
  content: '';
  background: #fff;
  box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: calc(100% - 110px);
  left: 0px;
  bottom: 0px;
}

.news-block-two .inner-box .image-box{
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 10px;
}

.news-block-two .inner-box .image-box img{
  width: 100%;
  border-radius: 10px;
  transition: all 500ms ease;
}

.news-block-two .inner-box:hover .image-box img{
  transform: scale(1.05);
}

.news-block-two .inner-box .lower-content{
  position: relative;
  padding-top: 44px;
}

.news-block-two .inner-box .lower-content .post-date{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -55px;
  right: 30px;
  z-index: 1;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: var(--theme-color);
}

.news-block-two .inner-box .lower-content .post-date h4{
  font-size: 22px;
  line-height: 20px;
  color: #fff;
}

.news-block-two .inner-box .lower-content .post-date h4 span{
  display: block;
  font-weight: 400;
  font-size: 16px;
}

.news-block-two .inner-box .lower-content h3{
  display: block;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}

.news-block-two .inner-box .lower-content h3 a{
  display: inline-block;
  color: var(--title-color);
}

.news-block-two .inner-box .lower-content h3 a:hover{
  color: var(--theme-color);
}

.news-block-two .inner-box .lower-content p{
  position: relative;
  display: block;
  padding-bottom: 29px;
  border-bottom: 1px solid #E5E5E5;
}

.news-block-two .inner-box .lower-content .post-info{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
}

.news-block-two .inner-box .lower-content .post-info:before{
  position: absolute;
  content: '';
  background: #D9D9D9;
  width: 1px;
  height: 25px;
  top: 17px;
  left: 45%;
}

.news-block-two .inner-box .lower-content .post-info li{
  position: relative;
  display: block;
  font-size: 16px;
  color: #6E6E6E;
  padding-left: 25px;
}

.news-block-two .inner-box .lower-content .post-info li a{
  display: inline-block;
  color: #6E6E6E;
}

.news-block-two .inner-box .lower-content .post-info li i{
  position: absolute;
  left: 0px;
  top: 5px;
  font-size: 16px;
  color: var(--theme-color);
}

.news-block-two .inner-box .lower-content .post-info li a:hover{
  color: var(--theme-color);
}


/** sidebar-page-container **/

.sidebar-page-container{
  position: relative;
}

.news-block-three .inner-box{
  position: relative;
  display: block;
  padding: 0px 40px 40px 40px;
  margin-bottom: 65px;
}

.news-block-three .inner-box:before{
  position: absolute;
  content: '';
  background: #fff;
  box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 50%;
  left: 0px;
  bottom: 0px;
}

.news-block-three .inner-box .image-box{
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 10px;
}

.news-block-three .inner-box .image-box img{
  width: 100%;
  border-radius: 10px;
  transition: all 500ms ease;
}

.news-block-three .inner-box:hover .image-box img{
  transform: scale(1.05);
}

.news-block-three .inner-box .lower-content{
  position: relative;
  padding-top: 24px;
}

.news-block-three .inner-box .lower-content .post-date{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -55px;
  right: 30px;
  z-index: 1;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: var(--theme-color);
}

.news-block-three .inner-box .lower-content .post-date h4{
  font-size: 22px;
  line-height: 20px;
  color: #fff;
}

.news-block-three .inner-box .lower-content .post-date h4 span{
  display: block;
  font-weight: 400;
  font-size: 16px;
}

.news-block-three .inner-box .lower-content .post-info{
  position: relative;
  display: flex;
  align-items: center;
}

.news-block-three .inner-box .lower-content .post-info li{
  position: relative;
  display: block;
  font-size: 16px;
  color: rgba(110, 110, 110, 1);
  padding-left: 25px;
  margin-right: 57px;
}

.news-block-three .inner-box .lower-content .post-info li:before{
  position: absolute;
  content: '';
  background: rgba(217, 217, 217, 1);
  width: 1px;
  height: 25px;
  top: 0px;
  right: -29px;
}

.news-block-three .inner-box .lower-content .post-info li:last-child:before{
  display: none;
}

.news-block-three .inner-box .lower-content .post-info li:last-child{
  margin: 0px !important;
}

.news-block-three .inner-box .lower-content .post-info li i{
  position: absolute;
  left: 0px;
  top: 6px;
  font-size: 16px;
  color: var(--theme-color);
}

.news-block-three .inner-box .lower-content .post-info li a{
  display: inline-block;
  color: rgba(110, 110, 110, 1);
}

.news-block-three .inner-box .lower-content .post-info li a:hover{
  color: var(--theme-color);
}

.news-block-three .inner-box .lower-content h3{
  position: relative;
  display: block;
  font-size: 32px;
  line-height: 44px;
  font-weight: 800;
  margin-bottom: 20px;
}

.news-block-three .inner-box .lower-content h3 a{
  display: inline-block;
  color: var(--title-color);
}

.news-block-three .inner-box .lower-content h3 a:hover{
  color: var(--theme-color);
}

.news-block-three .inner-box .lower-content p{
  margin-bottom: 30px;
}

.news-block-three .inner-box .lower-content .btn-box a{
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-family: var(--title-font);
  font-weight: 700;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
  padding: 11px 33px;
}

.news-block-three .inner-box .lower-content .btn-box a:hover{
  color: #fff !important;
  background: var(--theme-color);
}



/** RTL **/

.rtl .news-block-one .inner-box .post-info li{
  margin-right: 0px;
  margin-left: 20px;
}

.rtl .news-block-three .inner-box .lower-content .post-date{
  right: inherit;
  left: 30px;
}



/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){

}

@media only screen and (max-width: 767px){
  .news-section{
    padding: 70px 0px 40px 0px;
  }

  .news-style-two{
    padding: 0px 0px 40px 0px;
  }

  .news-style-two .pattern-layer{
    display: none;
  }

  .blog-grid{
    padding: 70px 0px;
  }

  .blog-image{
    padding: 70px 0px;
  }

  .sidebar-page-container{
    padding: 70px 0px;
  }

  .news-block-three .inner-box{
    padding: 0px;
  }

  .news-block-three .inner-box:before{
    display: none;
  }
}

@media only screen and (max-width: 599px){

}

@media only screen and (max-width: 499px){
  .news-block-one .inner-box{
    padding-top: 100px;
  }

  .news-block-three .inner-box .lower-content h3{
    font-size: 24px;
    line-height: 30px;
  }
}










































