
/** career-section **/

.career-section{
  position: relative;
  background: #F6F6F6;
}

.career-section .bg-layer{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.05;
}

.career-block-one .inner-box{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 30px 60px 30px 30px;
  margin-bottom: 30px;
}

.career-block-one .inner-box .author-box{
  position: relative;
  display: flex;
  align-items: center;
}

.career-block-one .inner-box .author-box .thumb-box{
  position: relative;
  display: inline-block;
  margin-right: 30px;
}

.career-block-one .inner-box .author-box .thumb-box img{
  width: 100%;
}

.career-block-one .inner-box .author-box h3{
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 7px;
}

.career-block-one .inner-box .author-box .designation{
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
}

.career-block-one .inner-box .info-box{
  position: relative;
}

.career-block-one .inner-box .author-box:before{
  position: absolute;
  content: '';
  background: #DEDEDE;
  width: 1px;
  height: 80px;
  top: 30px;
  right: -80px;
}

.career-block-one .inner-box .info-box li{
  position: relative;
  display: block;
  padding-left: 31px;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 10px;
}

.career-block-one .inner-box .info-box li:last-child{
  margin-bottom: 0px;
}

.career-block-one .inner-box .info-box li i{
  position: absolute;
  left: 0px;
  top: 4px;
  font-size: 20px;
  color: var(--theme-color);
}

.career-block-one .inner-box .btn-box a{
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-family: var(--title-font);
  font-weight: 700;
  text-transform: capitalize;
  color: var(--theme-color);
  border: solid;
  border-width: 1px;
  border-color: var(--theme-color);
  padding: 16px 39px;
}

.career-block-one .inner-box .btn-box a:hover{
  color: #fff !important;
  background: var(--theme-color);
}



/** RTL **/


.rtl .career-block-one .inner-box .author-box .thumb-box{
  margin-right: 0px;
  margin-left: 20px;
}

.rtl .career-block-one .inner-box .info-box li{
  padding-left: 0px;
  padding-right: 31px;
}

.rtl .career-block-one .inner-box .info-box li i{
  left: inherit;
  right: 0px;
}

.rtl .career-block-one .inner-box .author-box:before{
  right: inherit;
  left: -80px;
}


/** responsive-css **/

@media only screen and (max-width: 1200px){
  .career-block-one .inner-box .author-box:before{
    display: none;
  }
}

@media only screen and (max-width: 991px){
  .career-block-one .inner-box{
    display: block;
  }

  .career-block-one .inner-box .author-box{
    margin-bottom: 20px;
  }

  .career-block-one .inner-box .info-box{
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px){
  .career-section{
    padding: 70px 0px 40px 0px;
  }

  .career-block-one .inner-box{
    padding-right: 30px;
  }
}

@media only screen and (max-width: 599px){

}

@media only screen and (max-width: 499px){
  .career-block-one .inner-box .author-box{
    display: block;
  }

  .career-block-one .inner-box .author-box .thumb-box{
    margin: 0px 0px 20px 0px !important;
  }
}










































