
/** funfact-section **/

.funfact-section{
  position: relative;
}

.funfact-section .inner-container{
  position: relative;
  display: block;
  box-shadow: 0px 10px 50px 0px #0000001A;
  background: #fff;
  margin-top: -95px;
  z-index: 2;
}

.funfact-section .funfact-block-one{
  float: left;
  width: 33.333%;
}

.funfact-block-one .inner-box{
  position: relative;
  display: block;
  padding: 54px 30px 51px 170px;
}

.funfact-block-one .inner-box .icon-box{
  position: absolute;
  display: inline-block;
  left: 50px;
  top: 50px;
  width: 90px;
  height: 90px;
  line-height: 90px;
  font-size: 40px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  background: rgba(255, 255, 255, 0.07);
}

.funfact-block-one:nth-child(2) .inner-box{
  background: var(--secondary-color);
}

.funfact-block-one:last-child .inner-box{
  background: var(--theme-color);
}

.funfact-block-one:last-child .inner-box .icon-box{
  background: rgba(255, 255, 255, 0.15);
}

.funfact-block-one:first-child .inner-box .icon-box{
  color: var(--theme-color);
  background: rgba(235, 41, 44, 0.10);
}

.funfact-block-one .inner-box .count-outer {
  position: relative;
  display: block;
  font-size: 40px;
  line-height: 52px;
  font-family: var(--title-font);
  color: #fff;
  font-weight: 800;
  margin-bottom: 5px;
}

.funfact-block-one:first-child .inner-box .count-outer{
  color: #222222;
}

.funfact-block-one .inner-box p{
  font-size: 22px;
  line-height: 26px;
  color: #fff;
  font-weight: 500;
}

.funfact-block-one:first-child .inner-box p{
  color: #676767;
}

/** funfact-style-two **/

.funfact-style-two{
  position: relative;
  background: #000;
  padding: 35px 0px;
}

.funfact-style-two .bg-layer{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.15;
}

.funfact-style-two .funfact-block-one .inner-box{
  background: transparent !important;
}

.funfact-style-two .funfact-block-one .inner-box .icon-box{
  background: rgba(255, 255, 255, 0.10) !important;
}

.funfact-style-two .funfact-block-one .inner-box .count-outer{
  color: #fff;
}

.funfact-style-two .funfact-block-one .inner-box p{
  color: #fff;
}

.funfact-style-two .funfact-block-one .inner-box:before{
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.10);
  width: 1px;
  height: calc(100% + 70px);
  top: -35px;
  right: -15px;
}

.funfact-style-two .funfact-block:last-child .funfact-block-one .inner-box:before{
  display: none;
}



/** RTL **/

.rtl .funfact-style-two .funfact-block-one .inner-box:before{
  right: inherit;
  left: -15px;
}



/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){
  .funfact-section .funfact-block-one{
    width: 50%;
  }

  .funfact-section .funfact-block-one:last-child{
    width: 100%;
  }
}

@media only screen and (max-width: 767px){
  .funfact-section .funfact-block-one{
    width: 100%;
  }

  .funfact-style-two .funfact-block-one .inner-box:before{
    display: none;
  }

  .funfact-style-two .funfact-block-one .inner-box{
    padding-bottom: 0px;
  }

  .funfact-style-two{
    padding-bottom: 85px;
  }

  .funfact-section.about-page{
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 599px){

}

@media only screen and (max-width: 499px){
  .funfact-block-one .inner-box{
    padding-left: 30px;
  }

  .funfact-block-one .inner-box .icon-box{
    position: relative;
    left: 0px;
    top: 0px;
    margin-bottom: 20px;
  }
}










































