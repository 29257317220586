
/** banner-section **/

.banner-section{
  position: relative;
  overflow: hidden;
}

.banner-section .pattern-layer{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}
  
.banner-carousel .slide-item{
  position: relative;
  padding: 180px 0px 225px 0px;
}

.banner-carousel .slide-item:before{
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.5;
  z-index: 1;
}
  
.banner-carousel .content-box{
  position: relative;
  display: block;
  z-index: 5;
  max-width: 600px;
}
  
.banner-carousel .slide-item .bg-layer{
  position: absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-transform:scale(1);
  -ms-transform:scale(1);
  transform:scale(1);
  -webkit-transition: all 8000ms linear;
  -moz-transition: all 8000ms linear;
  -ms-transition: all 8000ms linear;
  -o-transition: all 8000ms linear;
  transition: all 8000ms linear;
}
  
.banner-carousel .swiper-slide-active .slide-item .bg-layer{
  -webkit-transform:scale(1.25);
  -ms-transform:scale(1.25);
  transform:scale(1.25);
}
  
.banner-carousel .content-box h2{
  font-size: 70px;
  line-height: 74px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 30px;
  opacity: 0;
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.banner-carousel .content-box h2 span{
  color: var(--theme-color);
}
  
.banner-carousel .swiper-slide-active .content-box h2{
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 700ms;
  -moz-transition-delay: 700ms;
  -ms-transition-delay: 700ms;
  -o-transition-delay: 700ms;
  transition-delay: 700ms;
}
  
.banner-carousel .content-box p{
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 33px;
  color: #fff;
  opacity: 0;
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}
  
.banner-carousel .swiper-slide-active .content-box p{
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1000ms;
  -moz-transition-delay: 1000ms;
  -ms-transition-delay: 1000ms;
  -o-transition-delay: 1000ms;
  transition-delay: 1000ms;
}
  
.banner-carousel .content-box .btn-box{
  opacity: 0;
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}
  
.banner-carousel .swiper-slide-active .content-box .btn-box{
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1300ms;
  -moz-transition-delay: 1300ms;
  -ms-transition-delay: 1300ms;
  -o-transition-delay: 1300ms;
  transition-delay: 1300ms;
}

.banner-section .pattern-layer .pattern-1{
  position: absolute;
  top: 0px;
  right: 200px;
  width: 616px;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  -webkit-animation: service_hexagon_2 15s infinite linear;
  -moz-animation: service_hexagon_2 15s infinite linear;
  -o-animation: service_hexagon_2 15s infinite linear;
  animation: service_hexagon_2 15s infinite linear;
}

.banner-section .pattern-layer .pattern-2{
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 86px;
  height: 234px;
  background-repeat: no-repeat;
  z-index: 1;
}

.banner-section .owl-nav{
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -35px;
  width: 100%;
  z-index: 99;
}

.banner-section .owl-nav button{
  position: absolute;
  top: 0px;
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 76px;
  text-align: center;
  background: #fff;
  font-size: 30px;
  color: #000;
  border-radius: 50%;
  transition: all 500ms ease;
}

.banner-section .owl-nav button.owl-prev{
  left: 120px;
}

.banner-section .owl-nav button.owl-next{
  right: 120px;
}

.banner-section .owl-nav button:hover{
  color: #fff;
  background: var(--theme-color);
}

.banner-style-two .banner-carousel .slide-item{
  padding: 340px 0px 225px 0px;
}

.banner-style-two .banner-carousel .slide-item:before{
  background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  opacity: 0.6;
}

.banner-style-two .pattern-layer .pattern-3{
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 342px;
  height: 439px;
  background-repeat: no-repeat;
  mix-blend-mode: luminosity;
  z-index: 1;
}

.banner-style-three .banner-carousel .content-box h2{
  font-size: 64px;
}

.banner-style-three .banner-carousel .slide-item{
  padding: 160px 0px 245px 0px;
}

.banner-style-three .banner-carousel .slide-item:before{
  opacity: 1;
  background: #FF0003;
  mix-blend-mode: multiply;
}

.banner-style-three .banner-carousel .content-box .theme-btn.btn-three{
  color: var(--title-color) !important;
}

.banner-style-three .banner-carousel .content-box .theme-btn.btn-three:hover{
  color: #fff !important;
}

.banner-style-three .pattern-layer .pattern-4{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 346px;
  height: 447px;
  background-repeat: no-repeat;
  z-index: 1;
}

.banner-style-three .pattern-layer .pattern-5{
  position: absolute;
  top: 276px;
  right: 0px;
  width: 94px;
  height: 233px;
  background-repeat: no-repeat;
  z-index: 1;
}

.banner-style-three .shape{
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 98px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2;
}



/** RTL **/


.rtl .banner-carousel .content-box{
  text-align: right;
}


/** responsive-css **/

@media only screen and (max-width: 1700px){
  .banner-section .owl-nav{
    display: none;
  }
}

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){
  .banner-style-three .pattern-layer{
    display: none;
  }
}

@media only screen and (max-width: 767px){
  .banner-carousel .slide-item{
    padding: 90px 0px 136px 0px;
  }

  .banner-carousel .content-box h2{
    font-size: 40px;
    line-height: 50px;
  }

  .banner-section .pattern-layer{
    display: none;
  }

  .banner-style-two .banner-carousel .slide-item{
    padding: 280px 0px 100px 0px;
  }

  .banner-style-three .banner-carousel .content-box h2{
    font-size: 40px;
  }

  .banner-style-three .banner-carousel .slide-item{
    padding: 100px 0px 185px 0px;
  }
}

@media only screen and (max-width: 599px){

}

@media only screen and (max-width: 499px){

}










































