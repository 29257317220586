
/** process-section **/

.process-section{
  position: relative;
  background: #FAFAFA;
}

.process-section .bg-layer{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.05;
}

.process-section .inner-container{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.processing-block-one{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 290px;
  height: 290px;
  border-radius: 50%;
  background: #fff;
}

.processing-block-one:before{
  position: absolute;
  content: '';
  background: var(--theme-color);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  transform: scale(0,0);
  transition: all 500ms ease;
}

.processing-block-one:hover:before{
  transform: scale(1,1);
}

.processing-block-one .inner-box .count-text{
  position: absolute;
  top: -25px;
  right: -15px;
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  background: var(--theme-color);
  color: #fff;
  border-radius: 50%;
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.3);
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  padding: 22px 0px;
  z-index: 1;
  transition: all 500ms ease;
}

.processing-block-one .inner-box .count-text:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  transform: scale(0,0);
  z-index: -1;
  transition: all 500ms ease;
}

.processing-block-one:hover .inner-box .count-text:before{
  transform: scale(1,1);
}

.processing-block-one:hover .inner-box .count-text{
  color: var(--theme-color);
  background: #fff !important;
}

.processing-block-one .inner-box .count-text:after{
  position: absolute;
  content: '';
  width: 120px;
  height: 120px;
  left: -15px;
  top: -15px;
  border-radius: 50%;
  border: dashed;
  border-width: 2px;
  border-color: var(--theme-color);
}

.processing-block-one .inner-box{
  padding: 30px;
}

.processing-block-one .inner-box h3{
  display: block;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 14px;
  transition: all 500ms ease;
}

.processing-block-one:hover .inner-box h3,
.processing-block-one:hover .inner-box p{
  color: #fff;
}

.processing-block-one .inner-box p{
  transition: all 500ms ease;
}

.processing-block-one .arrow-shape{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 81px;
  height: 20px;
  background-repeat: no-repeat;
  right: -145px;
}



/** RTL **/

.rtl .processing-block-one .arrow-shape{
  right: inherit;
  left: -145px;
  transform: rotate(-180deg);
}



/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){

}

@media only screen and (max-width: 767px){
  .process-section .inner-container{
    display: block;
    max-width: 290px;
    margin: 0 auto;
  }

  .process-section{
    padding: 70px 0px;
  }

  .processing-block-one .arrow-shape{
    display: none;
  }

  .processing-block-one{
    margin-bottom: 60px;
  }

  .processing-block-one:last-child{
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 599px){

}

@media only screen and (max-width: 499px){
  .processing-block-one .inner-box .count-text{
    right: 15px;
  }
}










































