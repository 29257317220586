
/** calculator-section **/

.calculator-section{
  position: relative;
}

.content_block_two .content-box{
  position: relative;
  display: block;
  background: #F6F6F6;
  padding: 80px 80px 50px 80px;
  margin-right: -15px;
}

.content_block_two .content-box .form-group{
  position: relative;
  margin-bottom: 30px;
}

.content_block_two .content-box .form-group.none{
  display: none;
}

.content_block_two .content-box .form-group input[type='number']{
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  background: #fff;
  border: 1px solid #E6E6E6;
  font-size: 16px;
  color: #A3A3A3;
  padding: 10px 30px;
}

.content_block_two .content-box .form-group input[type='submit']{
  padding: 18px 50px;
}

.content_block_two .content-box .form-group input[type='submit']:hover{
  background: var(--secondary-color) !important;
}

.content_block_two .content-box .form-group input[type='button']{
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-family: var(--title-font);
  font-weight: 700;
  color: var(--theme-color);
  padding: 15px 35px;
  border: 1px solid var(--theme-color);
  cursor: pointer;
  transition: all 500ms ease;
}

.content_block_two .content-box .form-group input[type='button']:hover{
  color: #fff !important;
  background: var(--theme-color);
}

.calculator-section .image-box{
  position: relative;
  display: block;
  overflow: hidden;
  margin-left: -15px;
}

.calculator-section .image-box img{
  width: 100%;
}

.calculator-section .image-box:before{
  position: absolute;
  top: 0;
  left: -85%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.calculator-section .image-box:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.calculator-section .inner-container{
  position: relative;
  background: #F6F6F6;
}

.calculator-section .inner-container .shape{
  position: absolute;
  left: -100px;
  top: -73px;
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 8s infinite linear;
  animation: zoom-fade 8s infinite linear;
}

.calculator-section .light-icon{
  position: absolute;
  right: 0px;
  bottom: 300px;
  width: 137px;
  height: 170px;
  background-repeat: no-repeat;
}



/** RTL **/

.rtl .calculator-section .image-box{
  margin-left: 0px;
  margin-right: -15px;
}

.rtl .content_block_two .content-box{
  margin-right: 0px;
  margin-left: -15px;
}

.rtl .content_block_two .content-box .form-group input[type='submit']{
  margin-right: 0px;
  margin-left: 20px;
}



/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){
  .content_block_two .content-box,
  .calculator-section .image-box{
    margin: 0px !important;
  }
}

@media only screen and (max-width: 767px){
  .calculator-section{
    padding: 70px 0px;
  }

  .calculator-section .light-icon,
  .calculator-section .inner-container .shape{
    display: none;
  }
}

@media only screen and (max-width: 599px){
  .content_block_two .content-box{
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 499px){
  .content_block_two .content-box .form-group.d-flex{
    display: block !important;
  }

  .content_block_two .content-box .form-group input[type='submit']{
    margin-right: 0px;
    margin-bottom: 20px;
    width: 100%;
  }

  .content_block_two .content-box .form-group input[type='button']{
    width: 100%;
  }
}










































