
/** video-section **/

.video-section{
  position: relative;
  padding: 100px 0px 215px 0px;
  overflow: hidden;
}

.video-section .bg-layer:before{
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.5;
}

.video-section .inner-box{
  position: relative;
  max-width: 590px;
  width: 100%;
  margin: 0 auto;
}


.video-section .inner-box h2{
  position: relative;
  display: block;
  font-size: 40px;
  line-height: 50px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 100px;
}


.video-section .inner-box .video-btn a{
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 106px;
  text-align: center;
  font-size: 32px;
  color: #fff;
  border-radius: 50%;
  background: var(--theme-color);
}
.video-section .inner-box .video-btn a.lightbox-image{
    cursor: pointer;
}

.video-section .inner-box .video-btn a .border-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255,255,255,.5);
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  animation: squares 2.9s linear 0s infinite;
  -webkit-animation: squares 2.9s linear 0s infinite;
  -ms-animation: squares 2.9s linear 0s infinite;
  -o-animation: squares 2.9s linear 0s infinite;
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
  opacity: 0;
}

.video-section .inner-box .video-btn a .border-animation.border-2 {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
}

.video-section .inner-box .video-btn a .border-animation.border-3 {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
}

.video-section.alternat-2{
  padding-bottom: 150px;
}



/** RTL **/





/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){

}

@media only screen and (max-width: 767px){
  .video-section.alternat-2{
    padding-bottom: 100px;
  }

  .video-section .inner-box h2{
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 599px){

}

@media only screen and (max-width: 499px){

}










































