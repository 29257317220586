
/** page-title **/

.page-title{
  position: relative;
  padding: 137px 0px;
}

.page-title .bg-layer{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.page-title .bg-layer:before{
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.5;
}

.page-title .pattern-layer .pattern-1{
  position: absolute;
  left: 0px;
  bottom: 83px;
  width: 86px;
  height: 170px;
  background-repeat: no-repeat;
}

.page-title .pattern-layer .pattern-2{
  position: absolute;
  top: 0px;
  right: 120px;
  width: 279px;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-animation: service_hexagon_2 15s infinite linear;
  -moz-animation: service_hexagon_2 15s infinite linear;
  -o-animation: service_hexagon_2 15s infinite linear;
  animation: service_hexagon_2 15s infinite linear;
}

.page-title h1{
  position: relative;
  display: block;
  font-size: 64px;
  line-height: 74px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 19px;
}

.page-title .bread-crumb li{
  position: relative;
  display: inline-block;
  font-size: 18px;
  line-height: 32px;
  color: #fff;
  padding-right: 15px;
  margin-right: 5px;
}

.page-title .bread-crumb li:last-child{
  padding: 0px !important;
  margin: 0px !important;
}

.page-title .bread-crumb li a{
  display: inline-block;
  color: #fff;
}

.page-title .bread-crumb li a:hover{
  color: var(--theme-color);
}

.page-title .bread-crumb li:before{
  position: absolute;
  content: '/';
  top: 0px;
  right: 0px;
}

.page-title .bread-crumb li:last-child:before{
  display: none;
}


/** RTL **/


.rtl .page-title .bread-crumb li{
  padding-right: 0px;
  padding-left: 15px;
  margin-right: 0px;
  margin-left: 5px;
}

.rtl .page-title .bread-crumb li:before{
  right: inherit;
  left: 0px;
}


/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){

}

@media only screen and (max-width: 767px){
  .page-title h1{
    font-size: 50px;
    line-height: 60px;
  }

  .page-title{
    padding: 90px 0px;
  }
}

@media only screen and (max-width: 599px){

}

@media only screen and (max-width: 499px){

}










































